import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";

function Cards() {
  return (
    <div className="cards">
      <h1 className="cards-title">Games</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/LuminaRush.png"
              text="Wishlist on Steam!"
              label="Lumina Rush"
              path="https://store.steampowered.com/app/2872830/Lumina_Rush/"
            />
            <CardItem
              src="images/DanceFool.png"
              text="Global Game Jam 2024"
              label="Dance Fool"
              path="https://leeroysbro.itch.io/dance-fool"
            />
            <CardItem
              src="images/TeddyChainsawMassacre.png"
              text="RVA Game Jam Retreat 2023"
              label="Teddy Chainsaw Massacre"
              path="https://leeroysbro.itch.io/teddy-chainsaw-massacre"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
