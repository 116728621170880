import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about-container">
      <div className="about-wrapper">
        <div className="about-picture">
          <img
            src="images/PhotoOfBen.JPG"
            alt="about-me"
            className="about-picture-img"
          />
        </div>
        <div className="about-text">
          <h1 className="about-title">ABOUT</h1>
          <p className="about-content">
            Hi, my name is Benjamin Albert and I'm a game developer based in
            Richmond Virginia. I have always had a passion for making games and
            hope to continue this endevour through this company.<br></br>
            <br></br> Battered Lute Studios is a company focused on making
            unique and engaging experiences. The focus isnt on making something
            perfect but making something fun and exciting. As a one man team,
            I'm learning alot and I hope to share my experiences in growing and
            making incredible games.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
