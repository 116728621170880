import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Phone</h2>
            <p>(443) 827-1353</p>
          </div>
          <div className="footer-link-email-items">
            <h2 className="email">Email</h2>
            <p>batteredlutestudios@gmail.com</p>
          </div>
          <div className="footer-link-items">
            <h2>Location</h2>
            <p>Richmond, Virginia</p>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <small className="website-rights">Battered Lute Studios © 2024</small>
          <div className="social-icons">
            <Link
              className="social-icon-link instagram"
              to="https://www.instagram.com/battered.lute.studios/"
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </Link>
            <Link
              className="social-icon-link youtube"
              to="https://www.youtube.com/@batteredlutestudios"
              target="_blank"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube" />
            </Link>
            <Link
              className="social-icon-link twitter"
              to="https://twitter.com/batteredlutedev"
              target="_blank"
              aria-label="Twitter"
            >
              <i className="fab fa-twitter" />
            </Link>
            <Link
              className="social-icon-link tiktok"
              to="https://www.tiktok.com/@batteredlutestudi?lang=en"
              target="_blank"
              aria-label="Tiktok"
            >
              <i className="fab fa-tiktok" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Footer;
