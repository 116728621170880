import React from "react";
import "./HeroSection.css";
import "../App.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src="/videos/ShortTrailer.mp4" autoPlay loop muted playsInline />
      <h1>A One Man Game Studio</h1>
      <p>Creating exciting and fun games</p>
    </div>
  );
}

export default HeroSection;
