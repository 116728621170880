import React from "react";
import "../../App.css";
import HeroSection from "../HeroSection";
import Cards from "../Cards";
import Footer from "../Footer";
import About from "../About";

function Home() {
  return (
    <>
      <div id="home">
        <HeroSection />
      </div>
      <div id="games">
        <Cards />
      </div>
      <div id="about">
        <About />
      </div>
      <Footer />
    </>
  );
}

export default Home;
