import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <figure className="navbar-logo-image-wrap">
              <img
                src="/images/LogoWhite.png"
                alt="logo"
                className="navbar-logo-image"
              />
            </figure>
            <div className="navbar-logo-text">Battered Lute Studios</div>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <div
                className={click ? "nav-links-mobile" : "nav-links"}
                onClick={() => {
                  closeMobileMenu();
                  window.location.replace("/#home");
                }}
              >
                Home
              </div>
            </li>
            <li className="nav-item">
              <div
                className={click ? "nav-links-mobile" : "nav-links"}
                onClick={() => {
                  closeMobileMenu();
                  window.location.replace("/#games");
                }}
              >
                Games
              </div>
            </li>
            <li className="last-nav-item">
              <div
                className={click ? "nav-links-mobile" : "nav-links"}
                onClick={() => {
                  closeMobileMenu();
                  window.location.replace("/#about");
                }}
              >
                About
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
